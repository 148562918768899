// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/profile_education_background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profile-page-container[data-v-75e05e0e] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  padding-top: 8px; /* Reduced padding to decrease space at the top */\n}\n.header .icon[data-v-75e05e0e] {\n  font-size: 24px;\n}\n.title[data-v-75e05e0e] {\n  margin-top: 8px;\n}\n.location-arrow .icon[data-v-75e05e0e] {\n  font-size: 24px;\n  display: block;\n  margin: 16px auto;\n}\n\n/* Additional styles can be added as needed to match the design */", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
